<template>
	<div class="m33_1 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="goBack">
					<div class="top-iconClose">
						<img :src="require('@/assets/img/ic_close.svg')" alt="닫기" />
					</div>
				</a>
				<p>주소찾기</p>
			</div>
		</div>
		<div class="box-txt">
			<div class="box-addre">
				<div class="search-addre">
					<input
						@keyup.13="doSearch"
						ref="input"
						v-model="searchWord"
						type="text"
						placeholder="예) 판교역 235, 한남동 714"
					/>
					<button type="button" @click="doSearch">
						<img :src="require('@/assets/img/ic_search.svg')" alt="" />
					</button>
				</div>

				<div v-if="searchResult.length === 0" class="addre-txt">
					<p>주소찾기 Tip</p>
					<span
						>도로명이나 지역명을 이용해서 검색해 보세요<br />
						건물번호, 번지를 함께 입력하시면 더욱 정확한<br />결과가
						검색됩니다.<br />
						예) 판교역로 235, 제주 첨단로 242,<br />한남동 714
					</span>
				</div>

				<div v-else>
					<div
						v-for="(item, index) in searchResult"
						:key="index"
						class="search-result"
					>
						<a @click="doSelectAddress(item)">
							<p>{{ item.roadAddrPart1 }}</p>
							<p>{{ item.roadAddrPart2 }}</p>
							<div class="result-details number">
								<span>지번</span>
								<i>{{ item.jibunAddr }}</i>
							</div>
							<div class="result-details">
								<span>우편번호</span>
								<i>{{ item.zipNo }}</i>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="box-shadow"></div>
		</div>

		<div v-if="currentPage * countPerPage < totalCnt" class="btn-app">
			<a @click="doNext">
				<button type="button" class="btn-select">다음</button>
			</a>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import axios from 'axios';

import { JUSO_API_URL, JUSO_API_KEY } from '@/constants';

export default {
	name: 'AddressSearch',

	data() {
		return {
			currentPage: 1,
			totalCnt: 0,
			countPerPage: 10,

			searchWord: '',
			searchResult: [],
			selectedAddress: null,
		};
	},

	methods: {
		...mapActions(['goBack']),
		...mapMutations(['saveUserAddressState']),

		doSearch() {
			this.currentPage = 1;
			this.totalCnt = 0;
			this.searchResult = [];

			if (!this.checkSearchedWord(this.searchWord)) {
				return;
			}
			this.$refs.input.blur();
			this.fetchJusoData();
		},

		fetchJusoData() {
			let url = JUSO_API_URL;
			const data = {
				confmKey: JUSO_API_KEY,
				currentPage: this.currentPage,
				countPerPage: this.countPerPage,
				keyword: this.searchWord,
				resultType: 'json',
			};

			const query = new URLSearchParams(data).toString();
			url = url + '?' + query;

			axios
				.get(url)
				.then(res => {
					if (res && res.status === 200) {
						this.totalCnt = res.data.results.common.totalCount;
						const juso = res.data.results.juso;
						this.searchResult = [...this.searchResult, ...juso];
					}
				})
				.catch(err => {
					console.debug('ERROR', err);
				});
		},

		doSelectAddress(item) {
			console.debug('doSelectAddress', item);
			this.saveUserAddressState(item);
			this.$router.go(-1);
		},

		doNext() {
			if (!this.checkSearchedWord(this.searchWord)) {
				return;
			}
			this.currentPage = this.currentPage + 1;
			this.fetchJusoData();
		},

		checkSearchedWord(searchWord) {
			if (searchWord.length > 0) {
				//특수문자 제거
				var expText = /[%=><]/;
				if (expText.test(searchWord) == true) {
					alert('특수문자를 입력 할수 없습니다.');
					searchWord = searchWord.split(expText).join('');
					return false;
				}

				//특정문자열(sql예약어의 앞뒤공백포함) 제거
				var sqlArray = new Array(
					//sql 예약어
					'OR',
					'SELECT',
					'INSERT',
					'DELETE',
					'UPDATE',
					'CREATE',
					'DROP',
					'EXEC',
					'UNION',
					'FETCH',
					'DECLARE',
					'TRUNCATE',
				);

				var regex;
				for (var i = 0; i < sqlArray.length; i++) {
					regex = new RegExp(sqlArray[i], 'gi');

					if (regex.test(searchWord)) {
						alert(
							'"' + sqlArray[i] + '"와(과) 같은 특정문자로 검색할 수 없습니다.',
						);
						searchWord = searchWord.value.replace(regex, '');
						return false;
					}
				}
			}
			return true;
		},
	},
};
</script>
